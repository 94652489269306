// Import necessary dependencies
import React from "react";
import "./AppDownloadPage.css";
import appIcon from "./image/action_splash_transparent.png";

// Define your download link
const downloadLink = "https://xiaoxiaodou.club/action-universal-release.apk";

// Interface for update section props
interface UpdateSectionProps {
    title: string;
    version: string;
    updates: string[];
}

// Alert component
const Alert: React.FC = () => (
    <div className="parallax-section alert">
        <h3>
            很多人还不知道 Action 重启的消息，我们正在积极上架应用市场，欢迎加入
            Action 真爱群（群号在软件内）
        </h3>
    </div>
);

// Download link component
const DownloadLink: React.FC = () => (
    <div className="parallax-section">
        <a href={downloadLink} className="btn-download">
            点击下载最新版Action
        </a>
    </div>
);

interface VersionInfoProps {
    releaseVersion: string;
    debugVersion: string;
}

// Version info component
const VersionInfo: React.FC<VersionInfoProps> = ({
                                                     releaseVersion,
                                                     debugVersion,
                                                 }) => (
    <div className="parallax-section">
        <h4>最新正式版：{releaseVersion}</h4>
        <h4>最新测试版：{debugVersion}</h4>
    </div>
);

// Update section component
const UpdateSection: React.FC<UpdateSectionProps> = ({
                                                         title,
                                                         version,
                                                         updates,
                                                     }) => (
    <div className="parallax-section update-section">
        <div className="update-content">
            <h4>{title}</h4>
            <p>{version}</p>
            <ul>
                {updates.map((update, index) => (
                    <li key={index}>{update}</li>
                ))}
            </ul>
        </div>
    </div>
);

// Warning component
const Warning: React.FC = () => (
  <div className="parallax-section alert">
    <h3>
      逗戏来的同学请注意，Action 2015 年就已投入研发，2016
      年上线，为原创的首款语 C APP。孰先孰后，请勿本末倒置。
    </h3>
  </div>
);

// Main AppDownloadPage component
const AppDownloadPage: React.FC = () => {
  return (
    <div className="parallax-container">
      <div className="parallax-section update-section">
        <div className="update-content">
          <strong>
            如果在QQ或微信里打开，请点击右上角，然后在弹出菜单中选择“在浏览器中打开”（目前只支持安卓）
          </strong>
        </div>
      </div>
      <img src={appIcon} alt="Action：语言角色扮演神器" className="app-icon" />
      <div style={{ height: "2em" }}></div>
      <div className="download-section">
        <button
          className="download-button"
          onClick={() => {
            window.location.href = downloadLink;
          }}
        >
          点击下载
        </button>
      </div>
      {/* <img src={weixinQrCode} alt="微信公众号二维码" className="app-icon" /> */}
      {/* Components with parallax effect */}
      {/* <Alert /> */}
      {/* <DownloadLink /> */}
      <VersionInfo releaseVersion="3.3.6" debugVersion="3.4.0" />
      <UpdateSection
        title="2024 年 4 月更新"
        version="V3.3.6"
        updates={["1. 新增 Flutter 3.19 引擎，向跨平台开发过渡"]}
      />
      <UpdateSection
        title="2024 年 3 月更新"
        version="V3.3.3"
        updates={[
          "1. 接入热修复功能",
          "2. 解决 3.3.0 中，换头像会崩溃的问题",
          "3. 解决 3.3.0 中，发动态会崩溃的问题",
          "fix 1: 头像框错位问题",
          "fix 2: 发送图文页面实际显示照片为选取照片-1的问题",
        ]}
      />
      <UpdateSection
        title="2024 年 2 月更新"
        version="V3.3.0"
        updates={["接龙功能开放", "修复了一些已知问题，提升用户体验"]}
      />
      <UpdateSection
        title="2024 年 1 月更新"
        version="V3.2.4"
        updates={[
          "全新界面，专注的对戏体验",
          "升级云存储功能，对戏记录可（手动）上传至云端",
          "修复了一些已知 bug",
        ]}
      />
      <UpdateSection
        title="2023 年 12 月更新"
        version="V3.1.16"
        updates={[
          "适配安卓 13 版本",
          "解决部分系统下对戏截图无法保存到相册的问题",
        ]}
      />
      <UpdateSection
        title="2023 年 11 月更新"
        version="V3.1.8"
        updates={["适配安卓 12 版本", "修复部分 bug"]}
      />
      {/* <Warning /> */}
      <div className="footer">
        <div>
          © 2023 成都青南科技有限公司 |{" "}
          <a
            style={{
              color: "inherit",
              textDecoration: "underline",
            }}
            href="http://www.beian.miit.gov.cn/"
          >
            蜀ICP备2023014400号-2
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadPage;
